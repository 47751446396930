import { Component, OnInit } from '@angular/core';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';

@Component({
    selector: 'app-get-started',
    templateUrl: './get-started.component.html',
    styleUrls: ['./get-started.component.scss'],
})
export class GetStartedComponent implements OnInit {

    constructor(private iab: InAppBrowser) { }

    ngOnInit() { }

    openTermsOfServicePdf() {
        this.iab.create(
            'https://storage.googleapis.com/privacy-policy-enderago/PrivacyPolicy/TermsOfService.pdf',
            '_system',
            'location=yes'
        );
    }

    openPrivacyPdf() {
        this.iab.create(
            'https://storage.googleapis.com/privacy-policy-enderago/PrivacyPolicy/PrivacyPolicy.pdf',
            '_system',
            'location=yes'
        );
    }
}
