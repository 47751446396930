// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    firebase_dev: {
        //change using 'firebase use {project}'
        //run 'npm run-script build' in /angular folder
        //run 'firebase deploy --only hosting:mobile' in /fleet-portal folder

        //wayne-staging
        //apiKey: 'AIzaSyBD5id3wVFznw4DweFaVwyCuOyyYOb7sAo',
        //authDomain: 'wayne-staging.firebaseapp.com',
        //databaseURL: 'https://wayne-staging.firebaseio.com',
        //projectId: 'wayne-staging',

        //linear-yen-205406
        apiKey: 'AIzaSyCXBhLNuMb7hy4PYB4gTQQSv86kBfFIrbU',
        authDomain: 'linear-yen-205406.firebaseapp.com',
        databaseURL: 'https://linear-yen-205406.firebaseio.com',
        projectId: 'linear-yen-205406',

        storageBucket: '',
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
