import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FeedbackComponent } from './feedback.component';
import { NegativeComponent } from './negative/negative.component';
import { PositiveComponent } from './positive/positive.component';
import { HelpComponent } from './help/help.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';

const routes: Routes = [
  {
    path: 'feedback',
    component: FeedbackComponent,
    children: [
      {
        path: '',
        redirectTo: 'feedback',
        pathMatch: 'full'
      },
      {
        path: 'negative',
        component: NegativeComponent
      },
      {
        path: 'positive',
        component: PositiveComponent
      },
      {
        path: 'help',
        component: HelpComponent
      },
      {
        path: 'confirmation',
        component: ConfirmationComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FeedbackRoutingModule { }
