import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FeedbackComponent implements OnInit {
  router: String;
  constructor(private _router:Router, private cd: ChangeDetectorRef) {
    this.router = _router.url;
  }

  ngOnInit() {}

  model = {};
  fields = [
    {
      key: 'experience',
      type: 'radio',
      templateOptions: {
        label: 'Please choose one of the following:',
        required: true,
        options: [
          { value: 1, label: 'Great!'},
          { value: 2, label: 'Not so great!'},
          { value: 3, label: 'I need help'}
        ]
      },
    }
  ]
  form = new FormGroup({});

  submit() {
    switch(this.model['experience']) {
      case 1: {
        this._router.navigateByUrl('/feedback/positive')
        this.router = '/feedback/positive';
        break;
      }
      case 2: {
        this._router.navigateByUrl('feedback/negative')
        this.router = '/feedback/negative';
        break;
      }
      case 3: {
        this._router.navigateByUrl('feedback/help')
        this.router = '/feedback/help';
        break;
      }
      default: {
        break;
      }
    }
    this.cd.detectChanges();
  }
}
