import { AgmOverlays } from "agm-overlays"
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { LocationAccuracy } from '@ionic-native/location-accuracy/ngx';

import { GetStartedComponent } from './get-started/get-started.component';
import { ConsentComponent } from './consent/consent.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FeedbackModule } from './feedback/feedback.module';
import { FormlyMaterialModule } from '@ngx-formly/material';

import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database'
import { AngularFireAuthModule } from '@angular/fire/auth';

import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';

@NgModule({
    declarations: [
        AppComponent,
        GetStartedComponent,
        ConsentComponent
    ],
    entryComponents: [],
    imports: [
        BrowserModule,
        AgmOverlays,
        FeedbackModule,
        FormsModule,
        ReactiveFormsModule,
        FormlyModule,
        IonicModule.forRoot(),
        IonicStorageModule.forRoot(),
        AppRoutingModule,
        BrowserAnimationsModule,
        FormlyModule.forRoot(),
        FormlyMaterialModule,
        AngularFireModule,
        AngularFireDatabaseModule,
        AngularFireAuthModule
    ],
    providers: [
        StatusBar,
        SplashScreen,
        InAppBrowser,
        AndroidPermissions,
        Geolocation,
        LocationAccuracy,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
