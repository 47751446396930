import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormArray, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { Observable } from 'rxjs';

export interface StepType {
    label: string;
    fields: FormlyFieldConfig[];
}

@Component({
    selector: 'app-positive',
    templateUrl: './positive.component.html',
    styleUrls: ['./positive.component.scss'],
})

export class PositiveComponent implements OnInit {

    router: string;

    routes$: Observable<any[]>;
    routes: any;
    activeRoutes: any;
    addRoute: any;

    vehicles$: Observable<any[]>;
    vehicles: any;
    activeVehicles: any;
    addVehicles: any;

    constructor(
        private db: AngularFireDatabase,
        private _router: Router,
        private fs: AngularFirestore,
        private cd: ChangeDetectorRef) {
        this.router = _router.url;
    }

    ngOnInit() {
        this.cd.detectChanges();

        this.routes$ = this.db.list('routes').valueChanges();

        this.routes$.subscribe(value => {
            this.routes = value;

            this.activeRoutes = [];

            this.routes.forEach(route => {
                if (!route.visible) {
                    route.visible = false;
                }
                if (!route.active) {
                    route.active = false;
                }
                else {
                    this.addRoute = {}

                    this.addRoute.id = route.id;
                    this.addRoute.name = route.name;

                    this.activeRoutes.push(this.addRoute);
                }
            });

            //https://stackoverflow.com/questions/2802341/javascript-natural-sort-of-alphanumerical-strings
            this.activeRoutes = this.activeRoutes.sort(function (a, b) {
                return a.name.localeCompare(b.name, undefined, {
                    numeric: true,
                    sensitivity: 'base'
                });
            });

            this.steps[0].fields[3].templateOptions.options = this.activeRoutes;

        })

        this.vehicles$ = this.db.list('vehicles').valueChanges();

        this.vehicles$.subscribe(value => {
            this.vehicles = value;

            this.activeVehicles = [];

            this.vehicles.forEach(vehicle => {
                if (vehicle.device_name != "") {

                    this.addVehicles = {}

                    this.addVehicles.id = vehicle.id;
                    this.addVehicles.name = vehicle.name;

                    this.activeVehicles.push(this.addVehicles);
                }
            });

            //https://stackoverflow.com/questions/2802341/javascript-natural-sort-of-alphanumerical-strings
            this.activeVehicles = this.activeVehicles.sort(function (a, b) {
                return a.name.localeCompare(b.name, undefined, {
                    numeric: true,
                    sensitivity: 'base'
                });
            });

            this.steps[0].fields[4].templateOptions.options = this.activeVehicles;
        })
    }

    activedStep = 0;

    model = {};
    steps: StepType[] = [
        {
            label: 'Information',
            fields: [
                {
                    key: 'email',
                    type: 'input',
                    templateOptions: {
                        type: 'email',
                        label: 'Email ',
                        placeholder: 'user@email.com',
                        required: true,
                    }
                },
                {
                    key: 'name',
                    type: 'input',
                    templateOptions: {
                        type: 'text',
                        label: 'Name ',
                        placeholder: 'John Doe',
                    }
                },
                {
                    key: 'phone',
                    type: 'input',
                    templateOptions: {
                        type: 'text',
                        label: 'Phone ',
                        placeholder: '123-456-7890',
                    }
                },
                {
                    key: 'route',
                    type: 'select',
                    templateOptions: {
                        label: 'Route',
                        options: [],
                        valueProp: 'id',
                        labelProp: 'name',
                        change: (field, $event) => {
                            //console.log(field);
                            //console.log($event);

                            this.activeVehicles = [];

                            this.vehicles.forEach(vehicle => {
                                if (vehicle.route_id == $event.value) {

                                    this.addVehicles = {}

                                    this.addVehicles.id = vehicle.id;
                                    this.addVehicles.name = vehicle.name;

                                    this.activeVehicles.push(this.addVehicles);
                                }
                            });

                            //https://stackoverflow.com/questions/2802341/javascript-natural-sort-of-alphanumerical-strings
                            this.activeVehicles = this.activeVehicles.sort(function (a, b) {
                                return a.name.localeCompare(b.name, undefined, {
                                    numeric: true,
                                    sensitivity: 'base'
                                });
                            });

                            this.model['vehicle'] = "";

                            this.steps[0].fields[4].templateOptions.options = this.activeVehicles;

                        }
                    }
                },
                {
                    key: 'vehicle',
                    type: 'select',
                    templateOptions: {
                        label: 'Vehicle',
                        options: [],
                        valueProp: 'id',
                        labelProp: 'name'
                    }
                },
                {
                    key: 'experience',
                    type: 'input',
                    templateOptions: {
                        type: 'text',
                        label: 'How was your experience?',
                        placeholder: 'What went well?'
                    }
                }
            ]
        }
    ];

    payload = {
        category: "positive",
    }

    form = new FormArray(this.steps.map(() => new FormGroup({})));
    options = this.steps.map(() => <FormlyFormOptions>{});

    prevStep(step) {
        this.activedStep = step - 1;
    }

    nextStep(step) {
        this.activedStep = step + 1;
    }

    submit() {

        var vehicleName = "";
        var routeName = "";

        this.vehicles.forEach(vehicle => {
            if (vehicle.id == this.model['vehicle']) {
                vehicleName = vehicle.name;
            }
        });

        this.routes.forEach(route => {
            if (route.id == this.model['route']) {
                routeName = route.name;
            }
        });

        const currTime = new Date();
        this.payload['time'] = currTime.toISOString();
        if (this.model['experience']) {
            this.payload['details'] = this.model['experience'];
        }
        this.payload['email'] = this.model['email'];
        if (this.model['name']) {
            this.payload['name'] = this.model['name'];
        }
        if (this.model['phone']) {
            this.payload['phone'] = this.model['phone'];
        }
        if (this.model['route']) {
            this.payload['route'] = routeName;
        }
        if (this.model['vehicle']) {
            this.payload['vehicle'] = vehicleName;
        }
        this.fs.collection('feedback').add(this.payload);
        this._router.navigateByUrl('/feedback/confirmation', { state: ["Thank you! We look forward to seeing you again."] });
        this.router = '/feedback/confirmation'
    }
}
