import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { GetStartedComponent } from './get-started/get-started.component';
import { ConsentComponent } from './consent/consent.component';

const routes: Routes = [
    { path: '', redirectTo: 'get-started', pathMatch: 'full' },
    { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomePageModule) },
    //{ path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
    { path: 'get-started', component: GetStartedComponent },
    { path: 'consent', component: ConsentComponent },
    { path: 'fleet', loadChildren: () => import('./fleet/fleet.module').then(m => m.FleetPageModule) }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
