import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FeedbackRoutingModule } from './feedback-routing.module';
import { FeedbackComponent } from './feedback.component';
import { NegativeComponent } from './negative/negative.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { MatStepperModule } from '@angular/material/stepper';
import { PositiveComponent } from './positive/positive.component';
import { HelpComponent } from './help/help.component';
import { ConfirmationComponent } from './confirmation/confirmation.component'
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore'
import { environment } from '../../environments/environment';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { IonicModule } from '@ionic/angular';

@NgModule({
  declarations: [
    FeedbackComponent,
    NegativeComponent,
    PositiveComponent,
    HelpComponent,
    ConfirmationComponent
  ],
  imports: [
    CommonModule,
    FeedbackRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    FormlyModule,
    FormlyModule.forRoot(),
    MatStepperModule,
    AngularFireModule.initializeApp(environment.firebase_dev),
    AngularFirestoreModule,
    MatIconModule,
    IonicModule,
    MatButtonModule
  ]
})
export class FeedbackModule { }
