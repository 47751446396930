import { Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Router } from '@angular/router';

import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { LocationAccuracy } from '@ionic-native/location-accuracy/ngx';


@Component({
    selector: 'app-consent',
    templateUrl: './consent.component.html',
    styleUrls: ['./consent.component.scss'],
})

export class ConsentComponent implements OnInit {

    lat: number;
    lng: number;
    accuracy: any;

    constructor(
        private platform: Platform,
        private router: Router,
        private androidPermissions: AndroidPermissions,
        private geolocation: Geolocation,
        private locationAccuracy: LocationAccuracy
    ) { }

    ngOnInit() {
        this.checkPlatformAndGPSPermission();
    }

    checkPlatformAndGPSPermission() {
        // skip gps request if non-cordova (browser) platform
        this.platform.ready().then(() => {
            if (this.platform.is('android')) {
                console.log('android');
                this.checkGPSPermission();
                this.router.navigate(['/fleet']);
            } else if (this.platform.is('ios')) {
                console.log('ios');
                this.checkGPSPermission();
                this.router.navigate(['/fleet']);
            } else {
                // skip
                this.router.navigate(['/fleet']);
            }
        });
    }

    // https://www.freakyjolly.com/ionic-4-turn-on-device-gps-in-ionic-4-application-without-leaving-app-using-ionic-native-plugin/
    checkGPSPermission() {
        this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.ACCESS_COARSE_LOCATION).then(
            result => {
                if (result.hasPermission) {

                    //If having permission show 'Turn On GPS' dialogue
                    this.askToTurnOnGPS();
                } else {

                    //If not having permission ask for permission
                    this.requestGPSPermission();
                }
            },
            err => {
                console.log(err)
            }
        );
    }

    requestGPSPermission() {
        this.locationAccuracy.canRequest().then((canRequest: boolean) => {
            if (canRequest) {
                console.log("4");
            } else {
                //Show 'GPS Permission Request' dialogue
                this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.ACCESS_COARSE_LOCATION)
                    .then(
                        () => {
                            // call method to turn on GPS
                            this.askToTurnOnGPS();
                        },
                        error => {
                            //Show alert if user click on 'No Thanks'
                            console.log('requestPermission Error requesting location permissions ' + error)
                        }
                    );
            }
        });
    }

    askToTurnOnGPS() {
        this.locationAccuracy.request(this.locationAccuracy.REQUEST_PRIORITY_HIGH_ACCURACY).then(
            () => {
                // When GPS Turned ON call method to get Accurate location coordinates
                this.getLocationCoordinates()
            },
            error => console.log('Error requesting location permissions ' + JSON.stringify(error))
        );
    }

    getLocationCoordinates() {
        this.geolocation.getCurrentPosition().then((resp) => {
            this.lat = resp.coords.latitude;
            this.lng = resp.coords.longitude;
            this.accuracy = resp.coords.accuracy;
        }).catch((error) => {
            console.log('Error getting location' + error);
        });
    }

}
