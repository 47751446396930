import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
})
export class ConfirmationComponent implements OnInit {
  text: string;

  constructor(private router: Router) { }

  ngOnInit() {
    if(history.state) {
      this.text = history.state[0];
    }

    setTimeout(() => {
      this.router.navigateByUrl('/home');
    }, 5000)
  }

}
